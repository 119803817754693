var compareSectionManager = (function () {
  var section = $('.compare'),
      showMoreButton = $('#compare__show-more'),
      hiddenSection = section.find('.compare_hidden'),
      hideBlock,
      hideButton,
      isHiddenSectionShown = false;

  var init = function () {
    // if we have what init
    if( section.length > 0 )
    {
      _addHideBlock();

      hideBlock = $('.compare__hide-section');
      hideButton = $('#compare__hide-button');

      _initSticky();

      _addHideButtonListener();
      _addWindowOnScrollListener();
      _addShowMoreButtonListener();
    }
  };

  var _showHiddenSection = function () {
    isHiddenSectionShown = true;

    hiddenSection.show();
  };

  var _hideHiddenSection = function () {
    isHiddenSectionShown = false;

    hiddenSection.hide();
  };
  /**
   * Show more characteristics
   */
  var _addShowMoreButtonListener = function () {
    showMoreButton.on('click', function (e) {
      e.preventDefault();

      _showHiddenSection();

      // hide button
      $(this).hide();

      _showHideBlock();
    });
  };

  /**
   * Check if element is on the screen
   *
   * @param element
   * @returns {boolean}
   */
  function _isElementInView(element) {
    var pageTop = $(window).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).height();

    return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
  }

  /**
   * Disable display stackly block
   */
  function _disableSticky() {
    $('.compare__section.section-title').unstick();
  }

  /**
   * Init sticky for stick element on top
   */
  function _initSticky()
  {
    $('.compare__section.section-title').sticky({
      topSpacing:0,
      zIndex : 6
    });
  }

  var _showHideBlock = function () {
    hideBlock.show();
  };

  var _hideHideBlock = function () {
    hideBlock.hide();
  };

  var _addWindowOnScrollListener = function () {

    $(window).on('scroll', function (e) {

      // if out of the section - disable sticky
      if( !_isElementInView(section) )
      {
        _disableSticky();
        _hideHideBlock();
      }
      else
      {
        _initSticky();

        if( isHiddenSectionShown )
        {
          _showHideBlock();
        }
      }

    });

  };

  var _addHideButtonListener = function () {
    hideButton.on('click', function (e) {
      e.preventDefault();

      _hideHiddenSection();

      showMoreButton.show();

      _hideHideBlock();
    });
  };

  var _addHideBlock = function () {
    $('body').append(
        '<div class="compare__hide-section compare_hidden-section">' +
          '<button id="compare__hide-button" class="compare__hide-button">&#8212;</button>' +
        '</div>'
    );
  };

  return {
    init : init
  }
})();
